@import "../../scss/setup/variable";
@import "../../scss/mixins/media-query";


.choice-list {
    display: flex;
    border-radius: 20px;
    height: 40px;
    background-color: $passive-gray;
    padding: 5px;

    @include xs() {
        margin-bottom: 12px;
    }

    .choice-type {
        margin: 0;
        cursor: pointer;
        display: flex;
        align-items: center;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        .choice-for-caption {
            margin: 0 5px 0 0;
        }

        .form-check-input {
            margin: 0;
            position: static;
            display: none;

            & + .choice-type-text {
                height: 30px;
            }
        }

        .choice-type-text {
            border-radius: 15px;
            cursor: pointer;
            padding: 3px 16px;
            transition: border-color .3s ease-in;
            color: #98989b;
        }

        .form-check-input:checked + .choice-type-text {
            background-color: $deep-sky-blue;
            color: $white;
        }
    }
}


.choice-group-field {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    & > * {
        margin-right: 5px;
    }
    & > .choice-list {
        margin-right: 8px;

    }

    @include dl() {
        &+.filters {
            margin-top: 16px;

        }
    }

}
