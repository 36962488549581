/**
 * Overwrite bootstrap buttons
**/
@import "../setup/variable";
@import "../../scss/mixins/font-family";


@mixin badge {
    $class-name-list: success dark danger;
    $color-list: $success $charcoal-grey $danger;

    .badge {
        @include font-family-medium();
        font-size: 14px;
        font-weight: 500;
        line-height: 1.71;
        border-radius: 4px;
        padding: 1px 8px;

        @each $current-name in $class-name-list {
            $i: index($class-name-list, $current-name);
            $color: nth($color-list, $i);
    
            &.badge-#{$current-name} {
                background-color: $color;
            }
        }
    }
}
