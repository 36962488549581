@import "../../scss/setup/variable";
@import "../../scss/mixins/media-query";
@import "../../scss/mixins/font-family";

.delete-panel {
    text-align: left;
    .delete-user {
        max-width: 640px;
        word-break: break-all;
        margin-left: 8px;
    }
    .delete-content {
        margin-bottom: 24px;
    }
    .search-select .btn.display-text {
        width: 320px;
        max-width: 100%;
    }

    .spinner-container {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0px auto;
        width: 100%;
    }

    .hide {
        display: none;
    }

    .activation {
        border-bottom: 1px solid #ddd;
        margin: 0 0 .5rem;
        padding: 0 0 .5rem 0;
    }

    .remote-control {
        @include md() {
            width: 100%;
        }

        .caption {
            @include font-family-bold;
            height: 24px;
            color: $bluish-grey;
            font-size: 18px;
            line-height: 1.33;
            margin-bottom: 12px;
        }

        .remote-control-wrapper {
            display: flex;
            flex-wrap: nowrap;

            @include xs() {
                flex-wrap: wrap;
            }
        }
    }

    .form.section {
        border-bottom: 1px solid #F1F3F3;
        padding-bottom: 24px;
        margin-bottom: 24px;

        &:last-child {
            border-bottom: 0;
            padding-bottom: 0;
            margin-bottom: 0;
        }
    }

    .form-group {
        .form-check {
            padding-left: 0;
        }
        .view-mode {
            .view-control:empty::before {
                content: "N/A";
                opacity: 0.3;
            }
        }
    }
}
