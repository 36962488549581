@import "../../scss/setup/variable";
@import "../../scss/mixins/media-query";
@import "../../scss/mixins/util";

.user-list {
    height: 100%;
    width: 100%;

    .functional-group {
        text-align: left;

        .btn {
            margin: 0 8px 0 0;
        }
    }

    .search-section {
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        .search-for-caption {
            margin: 0 16px 0 0;
        }

        .search-type-text {
            border-radius: 15px;
            border: 1px solid #ccc;
            cursor: pointer;
            padding: 3px 10px;
            transition: border-color .3s ease-in;
        }

        .search-type {
            margin: 0 5px 0 0;
            cursor: pointer;
            display: flex;
            align-items: center;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            .form-check-input:checked + .search-type-text {
                border-color: $primary-light;
            }
        }
    }

    .list-view {
        .cell-plan_name {
            text-transform: capitalize;
        }
    }
}

