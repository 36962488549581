@import "../setup/variable";

@mixin font-family-default {
    font-family: TTNormsPro-Regular, $global-font-family;
}

@mixin font-family-bold {
    font-family: TTNormsPro-Bold, $global-font-family-bold;
    font-weight: bold;
}

@mixin font-family-medium {
    font-family: TTNormsPro-Medium, $global-font-family-medium;
}
