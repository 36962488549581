$screen-xs: 480px !default;
$screen-sm: 768px !default;
$screen-md: 992px !default;
$screen-dl: 1024px !default;
$screen-lg: 1200px !default;

@mixin xs() {
    @media (max-width: $screen-xs) {
        @content;
    }
}

@mixin sm() {
    @media (max-width: $screen-sm) {
        @content;
    }
}

@mixin md() {
    @media (max-width: $screen-md) {
        @content;
    }
}

@mixin dl() {
    @media (max-width: $screen-dl) {
        @content;
    }
}

@mixin lg() {
    @media (max-width: $screen-lg) {
        @content;
    }
}
