@import "../../scss/setup/variable";
@import "../../scss/mixins/media-query";
@import "../../scss/mixins/util";

.group-list {
    height: 100%;
    width: 100%;

    .functional-group {
        text-align: left;

        .btn {
            margin: 0 8px 0 0;
        }
    }

    .pagination-control-sec {
        .page-size-select {
            min-width: 90px;
        }
    }

    .list-view {
        .cell-plan_name {
            text-transform: capitalize;
        }
    }
}

