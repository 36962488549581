@import "../../scss/setup/variable";
@import "../../scss/mixins/media-query";

.pagination-control-sec {
    display: flex;
    @include xs() {
        justify-content: flex-start;
        align-items: start;
        flex-wrap: wrap;
        margin-top: 12px;
        width: 100%;
        flex-direction: column;
    }

}
.pagination-after-block {
    display: flex;
    flex-grow: 1;
    @include lg() {
        width: 100%;
    }
    @include xs() {
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
        .total-count {
            width: 100%;
            margin-top: 12px;
        }
        .last-updated {
            margin: auto 8px auto 0;
        }
    }
}

.pagination {
    margin: 2px 8px 0 8px;
    display: flex;
    padding-left: 0;
    list-style: none;
    border-radius: .25rem;

    @include xs() {
        margin: 0;
    }

    .page-item {
        margin: 0 8px 0 0;
        width: 36px;

        @include xs() {
            margin: 0 7px 0 0;
        }

        &:last-child {
            margin-right: 0;
        }

        &:focus {
            outline: none;
        }


        .page-link {
            cursor: pointer;
            padding: .5rem 0;
            text-align: center;
            width: 36px;

            &.fa-angle-double-right::before {
                padding-left: 3px;
            }
            &.fa-angle-double-left::before {
                padding-right: 3px;
            }
            // style for desktop
            @media not all and (hover: none) {
                &:hover {
                    background: rgba($very-light-pink,.3);
                    border-radius: 50%;
                    color: $charcoal-grey;
                }
            }

        }


        &.active .page-link {
            background: none;
            pointer-events: none;
            color: $deep-sky-blue;
        }

        .page-link,
        &.disabled .page-link {
            border: none;
            background: transparent;
            color: $charcoal-grey;
        }

        &.disabled .page-link {
            opacity: .3;
        }
    }
}

.last-updated {
    margin: auto 8px auto auto;
    font-size: 14px;
}

.btn-refresh {
    background: rgba($very-light-pink, .3);
    border-radius: 50%;
    display: block;
    border: none;
    cursor: pointer;
    transition: opacity .3s ease;
    color: $charcoal-grey;
    height: 40px;
    width: 40px;
    min-width: 40px;
    padding: 0;

    &:focus,
    &:active {
        outline: none;
    }

    &:hover {
        opacity: .8;
    }

    &[disabled] {
        pointer-events: none;
        opacity: .5;
    }

    &-icon {
        width: 20px;
        height: 20px;
        background-image: url(../../assets/icon-reflash.svg);
    }
}

.total-count {
    display: flex;
    align-items: center;
    margin: 0 16px 0 0;
    font-size: 14px;

    .label::after {
        content: ':';
        padding-right: 5px;
    }
}
