@import "../../scss/mixins/media-query";
@import "../../scss/mixins/util";
@import "../../scss/setup/variable";
@import "../../scss/mixins/font-family";

$start-color: #00D958; //Neko link color
$end-color: #5FBAD9; //Neko link color
$menu-color: #3a3f52; //Neko link color

$version-height: 96px;

.sidebar {
    background: rgba(0, 0, 0, .1);
    transition: background .5s ease-out;
    z-index: 9998;
    // hide on desktop
    width: 0;
    height: 0;

    @include sm() {
        width: 100%;
        height: 100%;
    }

    aside {
        background: $passive-gray;
        width: 240px;
        height: 100%;
        position: fixed;
        top: $main-desktop-header-height;
        left: 0;
        z-index: 9998;
        transition: all .3s ease-out;
        transform-origin: left;
        transform: translateX(0);
        overflow: auto;

        @include sm() {
            width: 70vw;
        }
        @include xs() {
            top: $main-mobile-header-height;
        }
    }

    &.hide {
        background: rgba(255, 255, 255, 0);
        width: 0;

        aside {
            // always show on desktop
            @include sm() {
                transform: translateX(-100%);
            }
        }

        .btn-close-menu {
            display: none;
        }
    }

    menu {
        height: calc( 100% - #{$version-height} - #{$main-desktop-header-height} );
        overflow: auto;
        padding: 0 0 96px 0;
        margin: 0;
    }

    .menu {
        list-style: none;
        padding: 0;
        margin: 0;
        text-align: left;

        .menu-category,
        .menu-item {
            color: $dark-blue;
        }

        .category {
            padding: 16.5px 40px;
            margin: 0;
            @include font-family-default;
            font-size: 18px;
            font-weight: normal;
            color: $dark-blue;
            cursor: pointer;

            &+.menu {
                display: none;

                &.openMenu {
                    display: block;
                }
            }

            &.isActive {
                font-weight: bold;
            }
        }

        .menu-link,
        .category-link {
            text-decoration: none;
            display: block;
            color: $dark-blue;

        }

        .menu-link {
            padding: 12px 40px 12px 55px;
            font-size: 14px;
            line-height: 16px;
        }

        .menu-item {

            &:hover {
                background-color: rgba(255, 255, 255, .1);

                .menu-link {
                    color: $dark-blue; //Neko
                }
            }
        }



        .active {
            background-color: rgba(23, 62, 111, 0.05);
            @include font-family-bold;
        }
    }

    .version {
        @include font-family-medium;
        color: $bluish-grey;
        font-size: 14px;
        width: 100%;
        margin: auto;
        line-height: $version-height;
        height: $version-height;
        opacity: .5;
        position: absolute;
        bottom: $main-desktop-header-height;
        left: 0;
        right: 0;
    }
}
