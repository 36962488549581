@import "../../scss/mixins/media-query";
@import "../../scss/mixins/font-family";
@import "../../scss/mixins/reset-appearance";
@import "../../scss/setup/variable";

.form {
    display: flex;
    flex-direction: column;
    position: relative;

    .spinner-container {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0px auto;
        width: 100%;
    }

    .hide {
        display: none;
    }

    .light-blocker {
        background: rgba(255, 255, 255, .3);
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        justify-content: center;
        align-items: center;

        .lds-ring::before,
        .lds-ring::after {
            border-color: #ccc transparent transparent transparent;
        }
    }

    .view-control {
        margin: 0;
        text-align: left;
        font-size: .875rem;

        &:empty::before {
            content: "--";
        }

        .badge {
            margin: 0 8px 0 0;
            font-size: .875rem;
        }
    }

    .group-selection {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-wrap: wrap;
        margin-top: -4px;

        label {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            font-weight: normal;
            white-space: nowrap;
            flex-flow: wrap;
            margin-bottom: 24px;

            &::last-child {
                margin: 0;
            }
        }
    }

    .radio {
        .radio-label {
            font-size: 18px !important;
            font-weight: 500 !important;
            line-height: 1.33 !important;
            @include font-family-medium();
        }
        .separation {
            width: 100%;
        }
        .label-description {
            margin: 4px 0 0 28px;
        }
    }

    .upload-info {
        width: 100%;
        padding: 32px 28px 0;
        margin-bottom: 24px;
        border: solid 1px rgba(215, 215, 215, 0.25);
        background-color: rgba(215, 215, 215, 0.25);
        text-align: left;
        .title {
            font-size: 18px;
            @include font-family-medium();
            margin-bottom: 4px;
        }
        .description {
            font-size: 14px;
            margin-bottom: 16px;
        }
        .file-input {
            display: none;
        }
        .file-upload {
            cursor: pointer;
            width: 100%;
        }
    }

    .control-group {
        margin: 0 0 4px 0;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        width: 100%;

        &.view-mode {
            margin: 0 0 20px 0;
        }

        &.disabled {
            .caption {
                color: $passive-dark;
            }
        }
    }

    .control-wrapper {
        display: flex;
        flex-direction: column;
        position: relative;
        width: 100%;
    }

    .caption {
        @include font-family-bold();
        display: inline-block;
        font-size: 14px;
        font-weight: 400;
        line-height: 1;
        text-transform: capitalize;
        margin: 0 0 7px 0;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        &.required:after {
            content: "*";
            padding: 0 2px;
        }
    }

    .caption,
    .form-check-label {
        user-select: none;
    }

    .form-control-file,
    .form-control {
        width: 100%;
    }

    .file-uploader {
        display: flex;
        align-items: center;
        flex-wrap: nowrap;

        .btn-browse {
            border-radius: 4px 0 0 4px;
            border-color: $alternative-text-light-color;
            color: $passive-dark;
            white-space: nowrap;
            z-index: 1;
            width: initial;
        }

        input[type="file"].form-control-file {
            visibility: hidden;
            height: 0;
            width: 0;
        }

        input[type="text"].file-name {
            width: initial;
            flex: 1;
            border-left: 0;
        }

        .file-name::placeholder {
            color: $passive-dark;
            @include font-family-default();
            font-size: 1rem;
        }

        .form-control {
            background-color: #fff;
            cursor: pointer;
            height: 40px;

            &:focus {
                border-color: $passive-dark;
            }
        }
    }

    .text-area {
        word-wrap: break-word;
        white-space: pre-wrap;
    }

    &.inline-form {
        .control-group {
            flex-direction: row;
            align-items: flex-start;
            text-align: left;

            &.view-mode {
                .caption {
                    transform: translateY(0);
                }
            }
        }

        .caption {
            margin: 0 8px 0 0;
            transform: translateY(11px);
            width: 40%;
        }

        .control-wrapper {
            width: 60%;

            &.full-width {
                width: 100%;
            }
        }
    }
}

.import-action {
    .modal-box {
        min-width: 800px;
        max-width: 800px;

        @include md() {
            min-width: 100vw;
            max-width: 100vw;
        }
    }
    .import-file {
        font-size: 14px;
        font-weight: normal;
        margin-left: 8px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 480px;
        width: 100%;
        display: inline-block;
        vertical-align: middle;
    }
}

.error-message {
    @include font-family-medium();
    color: $danger;
    margin: 4px 0 12px 0;
    font-size: 12px;
    font-weight: normal;
    height: 16px;
    line-height: 16px;
    text-align: left;
}
