@import "../../scss/setup/variable";
@import "../../scss/mixins/badge";
@import "../../scss/mixins/button";
@import "../../scss/mixins/form";
@import "../../scss/mixins/media-query";

.app {
    text-align: center;
    background-color: $white;
    display: flex;
    align-items: center;
    flex-direction: row;
    height: 100%;

    .main-content {
        width: 100vw;
        height: 100%;
    }

    .app-container {
        padding: $main-desktop-header-tot-height $view-desktop-side-padding $view-desktop-side-padding $main-desktop-sidebar-tot-width;
        height: 100%;

        @include sm() {
            padding: $main-mobile-header-tot-height $view-mobile-side-padding $view-desktop-side-padding $main-mobile-sidebar-tot-width;
        }
    }
    .app-feature-wrapper {
        overflow: hidden;
        display: flex;
        flex-direction: column;

        @include sm() {
            overflow: inherit;

            .list-view-bottom {
                margin-bottom: $view-mobile-side-padding;
                min-height: 500px;
            }
        }
        >* {
            min-height: fit-content;
        }
    }

    .icon {
        margin: 0 5px 0 0;
    }

    hr {
        width: 100%;
        height: 1px;
        background-color: $passive-green-gray;
        margin: 16px 0;
        border: 0;
    }

    @include badge();
    @include button();
    @include form();
}
