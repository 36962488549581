@import "../../../scss/setup/variable";
@import "../../../scss/mixins/media-query";

.user-action {
    .spinner-container {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0px auto;
        width: 100%;
    }

    .hide {
        display: none;
    }

    .modal-box {
        min-width: 800px;
        max-width: 800px;

        @include md() {
            min-width: 100vw;
            max-width: 100vw;
        }

        .control-group {
            width: 50%;

            @include md() {
                width: 100%;
            }
        }

        .email {
            width: 50%;
            @include xs() {
                flex-direction: unset;
            }
            @include md() {
                width: 100%
            }
            .email-at {
                margin: 0 8px 18px;
            }
            .email-domain {
                margin-top: 14px;
            }
        }
    }
}