@import "../../scss/mixins/media-query";

$padding: 48px !default;

.modal-box {
    background: #FFF;
    padding: 0 $padding $padding $padding;
    display: block;
    position: relative;
    max-height: 100%;
    min-width: 640px;
    max-width: 100%;
    overflow: auto;

    @include md() {
        min-width: 100vw;
        max-width: 100vw;
    }

    .modal-title {
        background: #fff;
        font-size: 36px;
        line-height: $padding;
        height: $padding * 2;
        font-weight: 200;
        text-align: left;
        display: flex;
        justify-content: space-between;
        margin: 0 0 32px 0;
        padding: $padding 0 0 0;
        overflow: hidden;
        position: sticky;
        top: 0;
        z-index: 1;
    }

    .modal-body {
        position: relative;
        padding: 0;
    }

    .btn.btn-secondary.btn-close {
        background: transparent;
        border: none;
        padding: 0;
        width: 40px;
        height: 40px;
        position: absolute;
        right: 0;
        top: $padding;
        all: unset;

        &:active:focus,
        &:hover {
            background-color: transparent;
            background: none;
            box-shadow: none;
            border: none;
        }

        img {
            display: block;
        }
    }

    .modal-button-group {
        justify-content: flex-end;
        margin-top: 16px;
    }
}
