@import "../../scss/setup/variable";

.lang-buttons {
    margin-bottom: 40px;
    color: $very-light-pink;

    @at-root {
        .account-profile .panel-body {
            .lang-button {
                font-size: 16px;
                color: $bluish-grey;
                background-color: initial;
                border: 0;
                padding: 0;
                height: 22px;
                line-height: 22px;

                &:active.btn, &.active.btn {
                    color: $dark-blue;
                    background-color: initial;
                    border-color: initial;
                    border: 0;
                }

                &.active.btn:focus {
                    box-shadow: none;
                }

                &.btn-secondary:not(:disabled):not(.disabled):active:focus {
                    box-shadow: none;
                }
            }
        }
    }


}