@import "../../scss/setup/variable";
@import "../../scss/mixins/media-query";
@import "../../scss/mixins/font-family";

.main-header {
    background: $passive-light;
    height: $main-desktop-header-height;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px $view-desktop-header-padding;
    margin: 0 0 $main-header-bottom-margin 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 9998;

    @include xs() {
        height: $main-mobile-header-height;
        padding: 5px $view-mobile-side-padding;
        max-width: 100vw;
    }

    .btn.btn-menu {
        background: transparent;
        background-color: transparent;
        border: none;
        color: $passive-dark;
        font-size: 1.4rem;
        padding: 0 10px;
        margin: 0 0 0 -10px;
        height: 44px;
        display: none;

        @include sm() {
            display: initial;
            height: 36px;

            img {
                display: inline-block;
                line-height: 36px;
                vertical-align: text-top;
            }
        }
    }

    .logo {


        &-area {
            display: flex;
            align-items: center;
            font-size: 24px;

            @include xs() {
                font-size: 15px;
            }
        }

        &-goshare {
            color: $dark-blue;
            @include font-family-bold();
            margin-right: 8px;
            line-height: 44px;
            font-weight: bold;
            @include xs() {
                margin-right: 4px;
            }
        }

        &-prod {
            color: $dark-blue;
            @include font-family-medium();
            line-height: 44px;
            font-weight: 500;
        }
    }

    .profile-sec {
        display: flex;
        justify-content: center;
        align-items: center;

        .company-name {
            color: $dark-blue;
            font-size: 16px;
            margin-right: 16px;
            line-height: normal;

            @include xs() {
                display: none;
            }
        }
    }

    .locale-select-form {
        margin: 0 5px 0 0;

        @include md() {
            display: none;
        }
    }
}
