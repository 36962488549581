@import "../../scss/mixins/media-query";

.page-size-select-wrapper {
    margin: 0 16px 0 0;
    min-width: 80px;

    @include xs() {
        margin-top: 8px;
    }

    .page-size-select {

        input[type="text"] {
            height: initial;
        }
    }
}
