@import "../../scss/mixins/media-query";

.period {
    display: flex;
    align-items: center;
    font-size: 14px;

    @include xs() {
        margin: 16px 0;
    }

    &-title {
        &::after {
            content: ":";
            margin: 0 3px 0 0;
        }
    }

    .divider {
        padding: 0 5px;
    }
}