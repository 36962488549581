@import "../../../scss/setup/variable";

.button {
    position: relative;
    overflow: hidden;
    &.loading:disabled {
        background-color: rgba(0, 0, 0, .5);
        pointer-events: none;
        .lds-ring {
            position: absolute;
            height: 32px;
            width: 32px;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
        }
    }
}

.btn-icon {
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100%;
    display: block;
    margin: 0 auto;
}

.btn-delete {
    background: rgba($very-light-pink, .3);
    opacity: 1;
    border-radius: 50%;
    display: block!important;
    border: none;
    cursor: pointer;
    transition: opacity .3s ease;
    color: $charcoal-grey;
    height: 40px;
    width: 40px;
    min-width: 40px;
    padding: 0;

    &:disabled {
        opacity: .5;
    }

    &-icon {
        width: 20px;
        height: 20px;
        background-image: url(../../../assets/icon-trash.svg);
    }
}