@import "../../scss/setup/variable";

.search-field {
    border: 2px solid $very-light-pink;
    position: relative;
    box-sizing: border-box;
    overflow: hidden;
    margin-right: 8px;

    input[name="search-text"].form-control {
        border: 0;
        padding-right: 32px;
        box-sizing: border-box;
        width: 100%;
        height: 36px;
    }

    .go-search {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        background: none;
        margin: auto;
        border: none;
        outline: none;
        padding: 2px 7px;

        &-icon {
            background-image: url(../../assets/icon-search.svg);
            height: 20px;
            width: 20px;
        }
    }

    .control-group {
        margin: 0;
    }

    .caption,
    .error-message {
        display: none;
    }

    .control-group {
        width: 100%;
    }
}
