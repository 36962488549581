// Font Family
$global-font-family: sans-serif, PingFangTC-Regular, Microsoft JhengHei !default;
$global-font-family-bold: sans-serif, PingFangTC-Semibold, Microsoft JhengHei !default;
$global-font-family-medium: sans-serif, PingFangSC-Medium, Microsoft JhengHei !default;;

// Font size
$desktop-font-size: 16px !default;
$mobile-font-size: 16px !default;;

// Colors
$white-smoke: #f5f5f5 !default;
$gray-92: #ebebeb !default;
$passive-green-gray: #F1F3F3 !default;
$passive-light: #F9F9F9 !default;
$passive-dark: #B9BCBF !default;
$passive-gray: #F3F3F3 !default;
$primary-light: #00D7FF !default;
$success: #00B946 !default;
$warning: #FBE75B !default;
$danger: #FF1923 !default;
$primary-text-color: #FFF !default;
$btn-blue:  #3bcbfd !default;

$dark-blue: #173e6f !default;
$deep-sky-blue: #0275ff !default;
$bluish-grey: #7c8c8f !default;
$bright-turquoise: #06f3f9 !default;
$black: #000000 !default;
$charcoal-grey: #323237 !default;
$very-light-pink: #d7d7d7 !default;
$shamrock: #00b946 !default;
$strawberry: #ff1923 !default;
$white: #fff !default;

// Font colors
$secondary-font-color: $passive-dark;
$alternative-text-light-color: #D7D7D7 !default;

// Common spacing
$view-desktop-side-padding: 40px !default;
$view-mobile-side-padding: 20px !default;

// Header height
$main-desktop-header-height: 72px !default;
$main-mobile-header-height: 52px !default;
$main-header-bottom-margin: 20px !default;
$main-desktop-header-tot-height: $main-desktop-header-height + 2*$main-header-bottom-margin;
$main-mobile-header-tot-height: $main-mobile-header-height + 2*$main-header-bottom-margin;

// Header space
$view-desktop-header-padding: 32px !default;

// Sidebar
$view-desktop-sidebar-width: 240px !default;
$main-desktop-sidebar-tot-width: $view-desktop-sidebar-width + $view-desktop-side-padding;
$main-mobile-sidebar-tot-width: 20px !default;