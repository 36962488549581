@import "../setup/variable";
@import "../../scss/mixins/font-family";

@mixin btn-select() {
    @include font-family-default;
    font-weight: normal;
    height: 40px;
    background-color: rgba(215, 215, 215, .3);
    border: 0;
    color: $charcoal-grey;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding: 0 16px;
    
    .arrow {
        &.up {
            transform: rotate(.5turn);
        }
    }
}