/**
 * Overwrite bootstrap buttons
 */
@import 'bootstrap';
@import "../setup/variable";
@import "../../scss/mixins/font-family";

$class-name-list: primary secondary success warning danger;
$colors-list: $deep-sky-blue $bluish-grey $success $warning $danger;

@mixin button() {
    .btn {
        @include font-family-bold;
        border: none;
        color: $primary-text-color;
        border-radius: 30px;
        font-size: 16px;
        padding: 0 24px;
        line-height: 40px;
        height: 40px;
        white-space: nowrap;
        transition: opacity .3s ease-in-out, color .3s ease-in-out, border .3s ease-in-out, background-color .3s ease-in-out;

        &:hover {
            opacity: .7;
        }

        &:disabled {
            pointer-events: none;
            border: 2px solid rgba($very-light-pink, .3);
            background-color: rgba($very-light-pink, .3);
            color: $alternative-text-light-color;
        }
    }

    .btn-sm {
        height: 40px;
        padding: 0 24px;
    }

    .btn-md {
        height: 48px;
        line-height: 48px;
    }

    @each $current-name in $class-name-list {
        $i: index($class-name-list, $current-name);
        $current-color: nth($colors-list, $i);

        .btn-#{$current-name} {
            color: #FFF;
            background-color: $current-color;

            &.btn:not(:disabled):not(.disabled):focus, &:not(:disabled):not(.disabled).btn:active {
                box-shadow: none;
            }
        }

        .btn-outline-#{$current-name} {
            border: 2px solid $deep-sky-blue;
            color: $deep-sky-blue;

            &.btn:not(:disabled):not(.disabled):focus,
            &:not(:disabled):not(.disabled).btn:active,
            &:hover {
                background: none;
                box-shadow: none;
                color: rgba($deep-sky-blue, .85);
                border: 2px solid rgba($deep-sky-blue, .85);
            }
        }
    }

    .btn-outline-secondary {
        &.btn:not(:disabled):not(.disabled):focus, &:not(:disabled):not(.disabled).btn:active {
            box-shadow: none;
            border: 2px solid $deep-sky-blue;
            color: $deep-sky-blue;
        }
    }

    .btn-outline-secondary {
        &.btn:not(:disabled):not(.disabled):focus, &:not(:disabled):not(.disabled).btn:active {
            box-shadow: none;
            border: 2px solid $deep-sky-blue;
            color: $deep-sky-blue;
        }
    }

    .btn-primary {
        &:hover {
            background-color: $primary-light;
        }
    }

    .btn-action {
        color: $primary-text-color;
        background-color: $btn-blue;
        &:hover {
            color: $primary-text-color;
        }
    }

    .btn-outline-link,
    .btn-link {
        color:$deep-sky-blue;
        text-decoration: underline;

        &:hover {
            color:$primary-light;
            text-decoration: underline;
        }
    }
}
