.app {
    label{
        &.duration {
            font-weight: normal;
            margin-bottom: 0;

            span {
                &:not(:last-child) {
                    &::after {
                        content: '\00a0';
                    }
                }

            }
        }
    }
}
