.popup-error-message {
    .modal-box {
        max-width: 800px;
        text-align: right;
    }

    .error {
        color: #ce0000;
        font-size: 2.25rem;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: left;
        font-size: 24px;
    }
    .error-paragraph {
        width: calc(100% - 3.25rem);
        word-break: break-all;
    }

    .icon {
        font-size: 3.25rem;
    }
}
