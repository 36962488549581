@import "../../scss/mixins/media-query";
@import "../../scss/mixins/button";
@import "../../scss/setup/variable";
@include button();

.login-page {
    position: fixed;
    top: 0;
    right: 0;
    padding: 0 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: $dark-blue;
    height: 100%;
    width: 100%;
    z-index: 9998;
    @include sm() {
        justify-content: center;
    }

    .btn-google-login {
        height: 48px;
    }

    .business-title {
        max-width: 100%;
        margin: 30vh 0 80px 0;
        @include sm() {
            margin-top: 0;
            margin-bottom: 40px;
            width: 80vw;
        }
    }

}
.login-loading {
    background-color: #fff;
    .lds-ring {
        &::before,
        &::after {
            border-color: $passive-dark transparent transparent transparent
        }
    }
}
