@import "../../scss/setup/variable";

.empty-list-blocker {
    justify-content: center;
    width: 100%;
    background-color: $white-smoke;
    color: $bluish-grey;
    font-size: 16px;
    font-weight: 600;
    padding: 48px 0;
    line-height: 36px;
    display: none;

    &.show {
        display: flex;
    }

    img {
        width: 36px;
        height: 36px;
        margin-right: 16px;
    }
    p {
        margin: 0;
    }

}