@import "../../scss/mixins/media-query";
@import "../../scss/mixins/util";
@import "../../scss/setup/variable";
@import "../../scss/mixins/font-family";

.action-header {

    @include xs() {
        align-items: flex-start;
    }
}

.import-btn {
    display: inline-flex;
    align-items: center;
}
