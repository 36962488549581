@import "../../scss/setup/variable";
@import "../../scss/mixins/media-query";

.trip-activity-list {
    width: 100%;
    height: 100%;


    .search-section {
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;
    }
    .search-field {
        @include xs() {
            margin-bottom: 16px;
        }
    }
    .search-for-caption {
        font-size: 14px;
        margin: 0 16px 0 0;
    }
    .list-view {
        .cell-content {
            .name {
                color: inherit;
                &:hover {
                    text-decoration: none;
                    cursor: initial;
                }
            }
        }
        .cell-corporate_type {
            .cell-content {
                span {
                    white-space: pre-wrap;
                }
            }
        }
    }

}