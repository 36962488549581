@import "../../scss/mixins/media-query";
@import "../../scss/mixins/select";
@import "../../scss/setup/variable";


.filters {
    text-align: left;

    @include xs() {
        max-width: 100%;

    }

    .custom-filter {
        margin: 0 8px 0 0;
        position: initial;
        &:last-child {
            margin: 0;
        }

        @include xs() {
            margin-top: 8px;
            margin-bottom: 8px;
            display: inline-flex;
            max-width: 100%;
        }
    }
}

.custom-filter {
    position: relative;
    display: inline-block;
    margin: 0 5px;

    @include sm () {
        margin: 5px 0;
    }

    .button-group {
        margin: 0;
    }

    .error {
        color: $strawberry;
        margin: 0 8px 0 0;
        font-size: 12px;
    }

    .filter-display-text {
        margin: 0 16px 0 0;

        @include xs() {
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    .blocker {
        background: transparent;
        z-index: 9;
        &.hide {
            display: none;
        }
    }

    .card {
        padding: 24px;
        margin: 0;
    }

    .card-body {
        padding: 0;
    }

    .filter-input {
        max-width: 200px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        &.fas::before {
            float: right;
            padding: 12px 48px 12px 16px;
        }
    }

    .btn.btn-filter {
        @include btn-select();
        display: flex;
        align-items: center;

        .arrow {
            margin: 0;
        }

        &.applied {
            background: #000;
            border-color: #000;
            color: #FFF;
        }

        .fas.fa-times {
            margin-left: 12px;
        }

        .fas.fa-times {
            margin-left: 12px;
        }

        .fas.fa-times:hover {
            opacity: .5;
        }
    }

    .collasible-panel {
        border-radius: 20px;
        box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.1);
        position: absolute;
        min-width: 300px;
        transform: translateY(7px);
        overflow: hidden;
        z-index: 9997;
        display: none;
        transition: all .2s ease-out;


        &.show {
            display: initial;
            @include xs() {
                left: calc((100vw - 300px)/2);
            }
        }

        .card {
            border: 0;
        }
    }

    .content {
        display: flex;
        flex-direction: column;

        .search-field {
            margin: 0 0 10px 0;
        }
    }

    .filter-option {
        display: flex;
    }

    .btn-group {
        padding: 0;
        display: block;
        text-align: right;
        margin: 0;
    }

    .form-control.is-invalid {
        background-image: none;
    }
}

.multiselect-filter {
    .option-container {
        width: 300px;
        max-height: 172px;
        margin: 0 0 10px 0;
        overflow: auto;
    }

    .filter-option {
        input[type="checkbox"] + span {
            width: calc(100% - 28px);
            height: auto;
        }

        &:last-child {
            margin: 0;
        }
    }

    .btn-group {
        text-align: left;
    }
}

.datetime-range-filter {
    input[type="date"] {
        min-height: 2.5rem;
    }
    .form-group {
        &:first-child {
            margin: 0 24px 0 0;
            @include sm() {
                margin: 0;
            }
        }
    }

    .datetime-box {
        line-height: 36px;
        width: 100%;
        padding: 0 12px;
        box-sizing: border-box;
    }

    .form-groups {
        display: flex;
        justify-content: space-between;

        @include md() {
            flex-direction: column;
        }

        .datetime-picker {
            width: 250px;
            margin: auto;

            .rdtPicker {
                position: relative;
            }
            .input-wrap {
                position: relative;

                .btn-reset {
                    position: absolute;
                    border: none;
                    right: 5px;
                    top: 0;
                    transform: translateY(50%);
                }
            }

            .form-control {
                display: none;
            }
        }
    }
    .btn-group {
        float: right;
    }
    &.is-mobile {
        .collasible-panel {
            left: calc((100vw - 300px) / 4);
        }
    }
    .collasible-panel {
        @include lg() {
            left: -20vw;
        }
    }

}
