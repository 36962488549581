@import "../../scss/mixins/media-query";

.button-group {
    display: flex;

    justify-content: flex-end;

    &.start {
        justify-content: flex-start;
    }


    .btn {
        margin: 0 8px 0 0;
    }

    .btn:last-child {
        margin: 0;
    }

    @include md() {
        justify-content: center;
    }

    &-mobile-start {
        @include xs(){
            flex-direction: row;
            width: 100%;
            margin-bottom: 12px;
        }
    }
}

.button-group-before-pagi {
    margin-right: 8px;
    @include xs() {
        width: 100%;
    }
}
