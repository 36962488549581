@import "../../../scss/mixins/media-query";
@import "../../../scss/mixins/select";
@import "../../../scss/setup/variable";

.search-select {
    position: relative;
    display: flex;

    input[type="text"].selected-id {
        height: 0;
        width: 0;
        padding: 0;
        border: 0;
        position: absolute;
        z-index: -1;
    }

    .btn.display-text {
        @include btn-select();
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
    }

    .option-container {
        list-style: none;
        margin: 0;
        padding: 0;
        font-weight: normal;
        max-height: 120px;
        overflow: auto;

        .option {
            cursor: pointer;
            padding: 3px 0;
            text-align: left;

            &:hover {
                background: rgba(0, 215, 255, .1);
            }
        }
    }

    .collasible-panel {
        border-radius: 20px;
        box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.1);
        overflow: hidden;
        position: absolute;
        top: calc(100% + 10px);
        z-index: 1;
        visibility: hidden;
        transition: all .2s ease-out;

        &.show {
            visibility: visible;
        }

        .search-field {
            margin: 0 0 10px 0;
            position: sticky;
            top: 15px;
        }

        .card {
            margin: 0;
            border: 0;
        }
    }

    .blocker {
        z-index: 1;
        background: transparent;
    }

    .hide {
        display: none;
    }
}
